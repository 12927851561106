import './tile-grid.scss';
import EventScroller from './../../lib/event-scroller';

class TileGridCounter {
    constructor ($counter, options) {
        const defaults = {
            eventScroller: null
        };
        this.settings = Object.assign({}, defaults, options);
        this.root = $counter;
        this.$counters = $counter.querySelectorAll('[data-tile-counter-end]');
        this.counter = [];
        this.animationStarted = false;
    }

    async initialize () {
        const eventScroller = new EventScroller({
            onScroll: async () => {
                if (eventScroller.onScreen(this.root)) {
                    if (!this.animationStarted) {
                        this.animationStarted = true;
                        for (let index = 0; index < this.$counters.length; index++) {
                            const startValue = parseInt(this.$counters[index].getAttribute('data-tile-counter-start'), 10);
                            const endValue = parseInt(this.$counters[index].getAttribute('data-tile-counter-end'), 10);

                            await this.createCounter(this.$counters[index], index, startValue, endValue);
                            this.startCounter(index);
                        }
                    }
                }
            }
        });

        eventScroller.initialize();
    }

    createCounter ($counter, index, startValue, endValue) {
        const easingFn = function (t, b, c, d) {
            const ts = (t /= d) * t;
            const tc = ts * t;
            return b + c * (tc + -3 * ts + 3 * t);
        };

        const options = {
            startVal: startValue,
            easingFn,
            separator: ''
        };

        const id = $counter.getAttribute('id');

        // Rückgabe eines Promise, das aufgelöst wird, sobald das CountUp-Objekt erstellt wurde.
        return import('countup.js').then(({ CountUp }) => {
            this.counter[index] = new CountUp(id, endValue, options);
        });
    }

    startCounter (index) {
        if (this.counter[index]) {
            this.counter[index].start();
        } else {
            console.error(`Counter at index ${index} is not initialized.`);
        }
    }
}

export { TileGridCounter };

window.addEventListener('content.loaded', (e) => {
    const eventDetails = e.detail;
    const $context = eventDetails.$context;

    if ($context) {
        const $tileGrid = $context.querySelectorAll('[data-tile-grid="root"]');

        for (let i = 0; i < $tileGrid.length; i++) {
            const $$tileGrid = new TileGridCounter($tileGrid[i]);
            $$tileGrid.initialize();
        }
    }
});
